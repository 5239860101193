/* You can add global styles to this file, and also import other style files */
@import "./styles/custom-theme";
@import "./styles/variables";

html,
body {
  font-family: "Nunito Sans", serif;
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

p,
h1,
h2,
h3,
h4 {
  margin-bottom: 0 !important;
}

button,
.mat-button,
.mat-raised-button {
  font-family: "Nunito Sans", serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.mt-10 {
  margin-top: 10px;
}

.mat-icon-small {
  font-size: 14px;
  height: 14px !important;
  width: 14px !important;
  overflow: inherit !important;
  line-height: 14px;
}

.responsive-container {
  margin: 0 auto;
  padding: 0 16px;
  width: 100%;

  /* breakpoints */
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media (min-width: 1400px) {
    max-width: 1320px;
  }
}

.custom-datepicker-field {
  .mat-mdc-icon-button svg {
    fill: $pink-color;
    width: 18px;
    height: 18px;
  }
}

/* Snack bar notification styles */
.custom-snackbar {
  margin-bottom: calc($footer-height + 10px) !important;
}

mat-snack-bar-container.success.mat-mdc-snack-bar-container
  .mat-mdc-snackbar-surface {
  background-color: $hover-color;
  color: $white-smoke-color;
  .mat-mdc-button:not(:disabled) {
    color: $white-smoke-color;
  }
}

mat-snack-bar-container.error.mat-mdc-snack-bar-container
  .mat-mdc-snackbar-surface {
  background-color: $red-color;
  color: $white-smoke-color;
  .mat-mdc-button:not(:disabled) {
    color: $white-smoke-color;
  }
}

mat-snack-bar-container.info.mat-mdc-snack-bar-container
  .mat-mdc-snackbar-surface {
  background-color: $pink-color;
  color: $white-smoke-color;
  .mat-mdc-button:not(:disabled) {
    color: $white-smoke-color;
  }
}
