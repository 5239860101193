$navbar-height: 55px;
$footer-height: 40px;
$search-section-height: 300px;

$odd-row-color: #eee;
$even-row-color: #f7f6fe;
$hover-row-light-color: #b4b4b433;
$white-smoke-color: whitesmoke;

// Palette structure built with this tool http://mcg.mbitson.com/#!?mcgpalette0=%23962067 Using the Trinetra webpage palette
// primary $blue1: #252c58;
// accent $pink-color: #962067;
$white-color: #fff;
$yellow-color: #e8bc5b;
$brand-blue1-color: #252c58;
$brand-blue2-color: #222a5e;
$brand-blue3-color: #012253;
$body-color: #464646;
$pink-color: #962067;
$subpara-color: #505050;
$hover-color: #116acc;
$red-color: #ff1b80;
$green-color: #8ddc6f;

$blue-brand: (
  50: #e5e6eb,
  100: #bec0cd,
  200: #9296ac,
  300: #666b8a,
  400: #464c71,
  500: #252c58,
  600: #212750,
  700: #1b2147,
  800: #161b3d,
  900: #0d102d,
  A100: #6a76ff,
  A200: #3747ff,
  A400: #0418ff,
  A700: #0013ea,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$pink-brand: (
  50: #f2e4ed,
  100: #e0bcd1,
  200: #cb90b3,
  300: #b66395,
  400: #a6417e,
  500: #962067,
  600: #8e1c5f,
  700: #831854,
  800: #79134a,
  900: #680b39,
  A100: #ff9bc7,
  A200: #ff68aa,
  A400: #ff358e,
  A700: #ff1b80,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
